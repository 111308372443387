<template>
    <transition name="fade">
        <div class="right-all" ref="sroll" v-show="this.top">
            <div class="r-a-left" v-show="this.left">
                <div class="r-a-l-left">电话咨询:4008340146</div>
                <div class="r-a-l-right"></div>
            </div>
            <div class="r-a-right" ref="right" @mouseleave="change()">
                <div @click="freetry()">
                    <div class="r-a-right-img"><img src="../../assets/img/右边（2）.png" alt=""></div>
                    <div>免费试用</div>
                </div>
                <div>
                    <div class="r-a-right-img r-a-right-i"><img src="../../assets/img/右（3）.png" alt=""></div>
                    <div>购买咨询</div>
                </div>
                <div @mouseenter="showleft()" @mouseleave="hiddenleft()">
                    <div class="r-a-right-img r-a-right-i"><img src="../../assets/img/右4.png" alt=""></div>
                    <div>电话咨询</div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import YSF from '@neysf/qiyu-web-sdk';
export default {
    data() {
        return {
            value: null,
            left: false,
            top: false
        }
    },
    onLoad() {

    },

    methods: {
        handsroll() {

        },
        showleft() {
            this.left = true
        },
        hiddenleft() {
            this.left = false
        },
        freetry() {
            this.$router.push({ name: 'freetry' })
        },
        serve() {
            YSF.init('3caa49f2b7f5454031c911f0548de2a7', {
            templateId: 123, // templateId表示自定义会话邀请的模板标识
            shuntId: 123, // shuntId表示访客选择多入口分流模版id
            sessionInvite: true, // sessionInvite表示是否开启会话邀请设置
            hidden: 0 // hidden表示是否隐藏访客端默认入口
        }).then(ysf => {
            ysf('open');
        }).catch(error => {
            console.log('sdk加载失败---', error);
        });
        },
        change() {
        }

    },
    mounted() {

        YSF.init('3caa49f2b7f5454031c911f0548de2a7', {
            templateId: 123, // templateId表示自定义会话邀请的模板标识
            shuntId: 123, // shuntId表示访客选择多入口分流模版id
            sessionInvite: true, // sessionInvite表示是否开启会话邀请设置
            hidden: 0 // hidden表示是否隐藏访客端默认入口
        }).then(ysf => {
            ysf('open');
        }).catch(error => {
            console.log('sdk加载失败---', error);
        });

        this.value = setInterval(() => {

            if (window.scrollY > 0 && window.outerWidth >= 1200) {
                this.top = true
            } else if (window.scrollY <= 0) {
                this.top = false
            }
        }, 100)


    },
    beforeDestroy() {
        clearInterval(this.value)
        this.value = null
    }
}



</script>
<style lang="scss">
.fade-leave,
// 离开前,进入后透明度是1
.fade-enter-to {
    opacity: 1;
}

.fade-leave-active,
.fade-enter-active {
    transition: all 1s; //过度是2秒
}

.fade-leave-to,
.fade-enter {
    opacity: 0;
}

.right-all {
    position: fixed;
    right: 0;
    z-index: 100;
}

.r-a-left {
    width: 283px;
    border-radius: 4px;
    margin-top: 160px;
    z-index: 100;
    position: fixed;
    right: 65px;
    top: 20%;
    font-size: 16px;
    color: #82abff;
    display: flex;
    justify-content: center;

}

.r-a-l-left {
    box-shadow: 0px 0px 8px rgb(196 196 196);
    background-color: white;
    width: 260px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    margin-top: 11px;
    border-radius: 4px;
}

.r-a-l-right {

    border: 8px solid white;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    height: 0px;
    margin-top: 35.5px;
}

.r-a-right {
    background-color: #2f60e9;
    height: 246px;
    width: 72px;
    border-radius: 4px;
    z-index: 100;
    position: fixed;
    right: 0;
    top: 20%;
}

.r-a-right div {
    color: white;
    text-align: center;
}

.r-a-right div:hover {
    cursor: pointer;
}

.r-a-right>div {
    color: white;
    width: 72px;
    height: 82px;
    font-size: 12px;
}

.r-a-right img {

    width: 24px;
    margin: 0 auto;
    margin-top: 16px;
}

.r-a-right-img {
    width: 39px;
    height: 52px;
    margin: 0 auto;
}

.r-a-right-i {
    border-top: 1px solid rgb(255, 255, 255, 0.3);
}
</style>