<template>
	<div class="indexall" ref="all" >
		<!-- 轮播图 -->
		<div class="swipers" >
			<div class="swiper mySwiper" id="swiperIndex">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="swper" style="background-color:#e5efff;">
							<div class="customer" style="background-color: #e5efff;">
								<div class="customer-left">
									<div class="cust-title">
										<span style="color: rgb(66, 116, 255);">网易七鱼</span>更懂你的智能客服
									</div>
									<div class="cust-body">
										<span style="display:block">全渠道接入，一站式响应客户需求,</span>
										<div class="cust-swpier">
											<div></div>
										</div>
										<span style="display:block">多维度提升服务效率</span>
									</div>
									<div class="cust-bottom">
										<div class="c-free" @click="freetry()">免费试用</div>
									</div>
								</div>
								<div class="c-img"><img src="../assets/img/更懂你.png" alt="" class="cust-img"></div>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="swper" style="background-color: #fff0de;">
							<div class="customer">
								<div class="customer-left">
									<div class="cust-title" style="color: rgb(100, 141, 255);;">
										智能重塑服务价值</div>
									<div class="cust-body">
										<span style="display:block">以世界前沿的人工智能技术，</span>
										<div class="cust-swpier">
											<div>提升客户的满意度</div>
											<div>降低管理成本</div>
											<div>提升企业客服工作效率</div>
											<div>提升企业客服工作效率</div>
										</div>
										<span style="display:block">让企业的服务真正发挥商业价值</span>
									</div>
									<div class="cust-bottom">
										<div class="c-free" @click="freetry()">免费试用</div>
									</div>
								</div>
								<div class="c-img"><img src="../assets/img/智能客服.png" alt="" class="cust-img"></div>
							</div>
						</div>
					</div>
					<div class="swiper-slide " style="background-color: #c3d2ff;hight:580px;">
						<div class="swper sw-two" @dbclick="freetry()">
							<div class="swo" style="color:#373d40">网易七鱼产品购买返现计划</div>
							<div class="swt" style="color:#373d40">现在购买网易七鱼产品最高可获<span>10%</span>返现</div>
							<div style="font-size: 20px;margin-top:15px;color:#373d40;">单笔最高<span>50000</span>元</div>
							<div class="sws" @click="buy()">了解详情</div>
						</div>
					</div>

				</div>
				<div class="swiper-pagination"></div>
			</div>
		</div>
		<!-- 中间分类 -->
		<div class="operation">
			<div class="o-body">
				<div class="o-b-b">
					<div class="o-title">
						现在注册即获 10% 返现奖励
					</div>
					<div class="o-down">
						<div class="o-d-all">
							<div class="o-d-left">加入七鱼购买奖励计划，成为新客成单立享高额奖励，单笔最高可返现 50000 元</div>
							<div href="" class="o-d-right" @click="bookr()">立即购买</div>
						</div>
						<div><img src="../assets/img/手指小人.jpg" alt=""></div>
					</div>
				</div>
				<div class="o-b-b">
					<div class="o-title">《客服培训指南》</div>
					<div class="o-down">
						<div class="o-d-all">
							<div class="o-d-left">助力企业客服团队培训体系搭建，搭建管理者与一线客服的信任桥梁</div>
							<div class="o-d-right" @click="book()">限时免费下载</div>
						</div>
						<div><img src="../assets/img/客服培训指南.jpg" alt=""></div>
					</div>
				</div>
				<div class="o-b-b">
					<div class="o-title">《客服管理指南》</div>
					<div class="o-down">
						<div class="o-d-all">
							<div class="o-d-left">客服工作热点、团队建设、管理新模式三大热点一网打尽</div>
							<div class="o-d-right" @click="bookt()">限时免费下载</div>
						</div>
						<div><img src="../assets/img/客服管理指南.jpg" alt=""></div>
					</div>
				</div>
				<div class="o-b-b">
					<div class="o-title">《2022客服中心智能化升级指南》</div>
					<div class="o-down">
						<div class="o-d-all">
							<div class="o-d-left">附实操指南，探索机器人不同阶段运营效果提升思路</div>
							<div @click="booko()" class="o-d-right">限时免费下载</div>
						</div>
						<div><img src="../assets/img/客服中心.jpg" alt=""></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 合作伙伴 -->
		<div class="m-homePage-scene-cnt">
			<h2>服务营销一体化解决方案专家</h2>
			<div class="m-h-body">
				<div>
					<div class="m-h-img"><img src="../assets/img/打字电脑.jpg" alt=""></div>
					<div class="m-h-down">
						<div class="m-h-d-head"><img src="../assets/img/留资访客.png" alt="">
							<div>留资访客</div>
						</div>
						<div class="m-h-d-body">无论是新客获取还是老客激活，一键配置即可实现7*24小时主动获取客户线索，赋能通知、调研、私域引流等业务。</div>
					</div>
				</div>
				<div>
					<div class="m-h-img"><img src="../assets/img/客服打电话（2）.jpg" alt=""></div>
					<div class="m-h-down">
						<div class="m-h-d-head"><img src="../assets/img/实时沟通.png" alt="">
							<div>实时沟通</div>
						</div>
						<div class="m-h-d-body">
							Web、App、微信生态、抖音、新浪微博、百度营销、Facebook等不同渠道的沟通需求将统一接入客服工作台，提供文字、语音、视频等多种沟通方式，让沟通更及时更准确。</div>
					</div>
				</div>
				<div>
					<div class="m-h-img"><img src="../assets/img/机器人沟通.jpg" alt=""></div>
					<div class="m-h-down">
						<div class="m-h-d-head"><img src="../assets/img/人机协同.png" alt="">
							<div>人机协同</div>
						</div>
						<div class="m-h-d-body">提供AI算法加持的机器人、人机共用知识库等工具，机器人可无缝转接至人工客服，满足会话、问答、互动等多个场景需求，打造高效协同的体验。</div>

					</div>
				</div>
				<div>
					<div class="m-h-img"><img src="../assets/img/数据洞察.jpg" alt=""></div>
					<div class="m-h-down">
						<div class="m-h-d-head"><img src="../assets/img/独眼怪.png" alt="">
							<div>数据洞察</div>
						</div>
						<div class="m-h-d-body">提供丰富的数据看板、数据报表、指标体系和可视化组件，实时数据、历史一览无余，为业务运营提供决策分析平台。</div>
					</div>
				</div>
			</div>
			<div class="m-free" @click="freetry">免费试用</div>
		</div>
		<div class="m-homePage-trust-cnt">
			<img src="../assets/img/合作伙伴.png" alt="" class="toger">
			<div class="m-h-mid">40万+客户的信赖之选</div>
			<img src="../assets/img/成本.jpg" alt="" class="toger">
			<div class="m-free" @click="freetry">免费试用</div>
		</div>
		<div class="m-homePage-touch">
			<h2 class="m-m-t-h2">在七鱼，用智能服务<span>高效触达</span>客户</h2>
			<div class="m-m-t-body">
				<div class="m-m-t-b-left"><img :src="this.pic"></div>
				<div class="m-m-t-b-right">
					<div style="text-align: left; margin-bottom: 24px;line-height: 150%;font-size: 16px;">
						网易七鱼以先进的AI能力提供在线机器人、语音机器人、坐席辅助和智能质检辅助人工，覆盖智能问答、售前咨询、精准获客、用户激活等多场景。</div>
					<div v-for="(item, i) in this.pick" @mouseenter="searchpic(i)" :key="i" class="m-m-b-r-down"
						ref="pickindex">
						<div class="m-m-t-b-title">{{ item.title }}</div>
						<div class="m-m-t-b-body">{{ item.body }}</div>
						<div class="m-m-t-b-foot" v-show="index == i">
							<div v-for="(tem) in item.foot">{{ tem.one }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="m-free" @click="freetry">免费试用</div>
		</div>
		<div class="choose">
			<div class="c-head">选择我们的理由</div>
			<div><img src="../assets/img/choose.png" alt=""></div>
		</div>

	</div>
</template>
<script>
import YSF from '@neysf/qiyu-web-sdk';
import Swiper from 'swiper'
import axios from 'axios';
import md5 from "md5";
import sha1 from "sha1"
import $ from 'jquery'
export default {
	data() {
		return {
			show: true,
			reproduct: null,
			name: "<br>",
			set: null,
			move: true,
			index: 0,
			pic: require('../assets/img/body（0）.png'),
			pick: [{
				title: "文本机器人",
				body: "7*24h多场景接入，精准识别访客需求并秒级响应。",
				foot: [{ one: "用户问题预测" }, { one: "先进的对话能力" }, { one: "转人工引擎" }, { one: "知识库运营" }],
				color: "#318c85",
				titlecolor: "#1f6660",
			}, {
				title: "语音机器人",
				body: "呼入呼出两大场景，依靠ASR/NLP/TTS打造沉浸式对话体验。",
				foot: [{ one: "ASR语音识别" }, { one: "NLP语义理解" }, { one: "海量语料" }, { one: "多线路对接" }],
				color: "#1893bb",
				titlecolor: "#006080",
			}, {
				title: "坐席辅助",
				body: "知识库辅助人工完成问题解答，人工反馈知识库持续优化。",
				foot: [{ one: "知识点推荐" }, { one: "场景分析" }, { one: "坐席数据统计" }, { one: "访客情绪识别" }],
				color: "#a67f63",
				titlecolor: "#705141",
			}, {
				title: "客户之声",
				body: "会话关键信息提取，访客异常情绪识别，时刻优化用户体验。",
				foot: [{ one: "情绪分析" }, { one: "低评分析" }, { one: "热词分析" }, { one: "场景分析" }],
				color: "#5360a7",
				titlecolor: "#333b66",
			}],
			product: [
				{ name: "在线客服", text: '在线服务，解决问题挖掘价值', webs: "" },
				{ name: "在线机器人", text: '更懂你的七鱼机器人' },
				{ name: "呼叫中心", text: '云呼叫系统，安全稳定更贴心' },
				{ name: "智能工单系统", text: '解决跨部门协同问题' },
				{ name: "智能质检", text: '高效智能解析，精确挖掘数据' },
				{ name: "视频客服", text: '促进销售转化，提高问题解决率' },
				{ name: "企微客服", text: '提深企微服务效率和质量' },
				{ name: "数据大屏", text: '炫酷的数据可视化展示' },
				{ name: "外呼机器人", text: '智能多轮自动外呼' },
				{ name: "售前留资机器人", text: '精准获取目标线索' },
				{ name: "平台电商版", text: '聚焦电商平台，协作提升服务质量' }],
			help: [
				{ name: "开发指南" },
				{ name: "下载中心" },
				{ name: "帮助中心" },
				{ name: "轻学院" },
				{ name: "博客" },
				{ name: "服务条款" },
			],
			authen: [
				{ name: "ISO27001信息安全管理", nem: "体系国际认证", pic: require('../assets/img/DNV.png') },
				{ name: "ISO27701个人隐私信息安全", nem: "管理体系认证 ", pic: require('../assets/img/DNV.png') },
				{ name: "CSA STAR云安全管理", nem: "体系认证", pic: require('../assets/img/DNV.png') },
				{ name: "ISO9001质量管理", nem: "体系认证", pic: require('../assets/img/iso.png') },
				{ name: "ISO20000IT服务管理", nem: "体系认证", pic: require('../assets/img/iso.png') },
				{ name: "CMMI3软件能力成熟度", nem: "模型集成认证", pic: require('../assets/img/cmmi.png') },],
			phone: [{
				item: "电话:4008340146"
			}, {
				item: "商务合作：bd@qi-kf.com"
			}, {
				item: "渠道合作：qudao@qi-kf.com"
			},]


		}
	},
	onLoad() {

	},

	methods: {
		buy() { this.$router.push({ name: 'reward' }) },
		qiwei() {
			this.$router.push({ name: 'custer' })
		},
		delet() {

			this.$refs.deleteall.style = "display:none;"
		},
		freetry() {
			this.$router.push({ name: 'freetry' })
			clearInterval(this.set)
			this.set = null
		},
		out() { this.reproduct = null },
		clearbox() {

			this.move = false
		},
		book() {
			this.$router.push({ name: 'book' })
		},
		bookt() {
			this.$router.push({ name: 'bookt' })
		},
		bookr() {
			this.$router.push({ name: 'reward' })
		},
		booko() {
			this.$router.push({ name: 'booko' })
		},
		clearboxnone() { this.move = true },
		searchpic(i) {
			this.index = i
			this.pic = require('../assets/img/body（' + this.index + '）.png')

			for (var j = 0; j <= this.pick.length - 1; j++) {
				this.$refs.pickindex[j].style.backgroundColor = "white"

				for (var h = 0; h < this.$refs.pickindex[j].children.length; h++) {

					this.$refs.pickindex[j].children[h].style.color = 'black'
					this.$refs.pickindex[j].children[1].style.color = '#676b73'
				}
			}
			this.$refs.pickindex[i].style.backgroundColor = this.pick[i].color

			for (var k = 0; k < this.$refs.pickindex[i].children.length; k++) {
				this.$refs.pickindex[i].children[k].style.color = 'white'
			}
			for (var l = 0; l < this.$refs.pickindex[i].children[2].children.length; l++) {
				this.$refs.pickindex[i].children[2].children[l].style.backgroundColor = this.pick[i].titlecolor
			}
		},
		produc() {
			this.reproduct = this.product

		}
	},
	beforeDestroy() {


		this.set = null
	},
	mounted() {
		
		if(this.$refs.all.offsetWidth <1200){
			this.$router.push({ name: 'indexm' })
		}
		
		
		this.$store.state.show = this.show

		this.$refs.pickindex[0].style.backgroundColor = this.pick[0].color
		for (var l = 0; l < this.$refs.pickindex[0].children[2].children.length; l++) {
			this.$refs.pickindex[0].children[2].children[l].style.backgroundColor = this.pick[0].titlecolor
		}
		for (var k = 0; k < this.$refs.pickindex[0].children.length; k++) {
			this.$refs.pickindex[0].children[k].style.color = 'white'

		}

	


		var mySwiper = new Swiper('#swiperIndex', {
			//配置分页器内容
			autoplay: {
				delay: 4000,
				disableOnInteraction: false// 当用户滑动图片后继续自动轮播
			},
			loop: true, // 循环模式选项
			pagination: {
				el: ".swiper-pagination",//换页器与哪个标签关联
				clickable: true//分页器是否可以点击
			},
			// 如果需要前进后退按钮

			//如果需要滚动条
			scrollbar: {
				el: '.swiper-scrollbar',
			},
		})



	},
}
</script>

<style lang="scss">
.indexall {
	.swiper-wrapper {
		z-index: 0 !important;
		position: none !important;

	}

	.sw-one {
		background: url(../assets/img/企微客服背景.jpg);
		background-size: 2400px;
		background-position: center;
		height: 400px !important;
		margin-top: 0px;
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: space-between;
		width: 1200px !important;

		div {
			color: white;
			text-align: left;
			span {
				color: #6da2fd;
			}
		}

		.swo {
			margin-top: 100px;
			font-size: 40px;
			color: #373d40
		}

		.swt {
			margin-top: 15px;
			font-size: 30px;
		}

		.sws {
			margin-top: 40px;
			background-color: #337eff;
			font-size: 20px;
			width: 140px;
			height: 50px;
			line-height: 50px;
			border-radius: 4px;
			text-align: center !important;
			cursor: pointer;
		}
	}

	.sw-two {
		background-image: url(../assets/img/购买的.png);
		background-size: cover;
		margin-top: 0px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;

		div {
			color: white;
			text-align: left;
			padding-left: 60px;
		}

		.swo {
			margin-top: 100px;
			font-size: 40px;
			padding-left: 60px;
		}

		.swt {
			margin-top: 15px;
			font-size: 30px;
			padding-left: 60px;
		}

		.sws {
			margin-left: 60px;
			margin-top: 40px;
			background-color: #337eff;
			font-size: 20px;
			width: 140px;
			height: 50px;
			line-height: 50px;
			border-radius: 4px;
			text-align: center !important;
			cursor: pointer;
			padding-left: 0px;
		}

		span {
			color: #4c8eff;
		}
	}

	.producs {
		width: 20%;
		padding: 20px 30px;
		margin-bottom: 10px;
	}

	.v-h-f-mid li {

		display: flex;
	}

	.g-new-sitemap-footer {
		padding: 16px;
		text-align: center;
		font-size: 12px;
		line-height: 1;
		color: rgba(26, 34, 51, 0.5);
		box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
	}

	.product {
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
	}

	.product div:hover {
		color: #4382ff;
	}

	.prd dt dd {
		display: block;
		width: 220px;
	}

	.l-p-dd div {
		font-size: 12px;
	}

	.auth {
		width: 340px !important;
	}

	.choose {
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 80px;
	}

	.choose img {
		width: 1200px;
	}

	.choose div {
		font-size: 32px;
		font-weight: 600;
		width: 1200px;
		text-align: center;
		margin-bottom: 20px;
	}

	.lists dt {
		margin-bottom: 24px;
		line-height: 24px;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;
	}

	.l-a-dd {
		display: flex;
		height: 50px;

	}

	.add {
		width: 96px !important;
	}

	.itemcc {
		height: 40px;
		margin-top: 3px;
	}

	.ddimg img {
		width: 48px;
		height: 49px;
	}

	.ddimg {
		margin-right: 12px;
	}

	.list dd {
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		color: #1a2233;
		margin-bottom: 15px;
		text-align: left;
		margin-left: 0;
		cursor: pointer;
	}

	.list {
		width: 220px;
	}

	.lists {
		display: flex;
	}

	.g-new-sitemap-content {
		margin: 0 auto;
		padding: 60px 0 40px;
		width: 1200px;
	}

	.bot-bottom {
		width: 100%;
		background-color: #cce4ff;
		height: 638px;
		margin-top: -30px;
	}

	.ph-free {
		position: absolute;
		top: 6px;
		right: 6px;
		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;
		cursor: pointer;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;



	}

	.phonenumber {
		position: relative;
	}

	.phonenumber input {
		display: block;
		padding: 16px 24px;
		box-sizing: border-box;
		width: 480px;
		line-height: 24px;
		font-size: 16px;
		border: none;
		border-radius: 28px;
		border: 2px solid transparent;
		position: relative;
	}

	.blue {
		color: #39f;
	}

	.g-n-title {
		margin-bottom: 32px;
		line-height: 150%;
		font-size: 32px;
		font-weight: 600;
		color: white;
	}

	.g-new-trial {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 1200px;
		height: 240px;
		background-color: #1a2233;
		border-radius: 16px;
	}

	.m-m-b-r-down {
		padding: 16px 24px;
		margin-top: 8px;
		border-radius: 8px;
		cursor: pointer;

	}

	.m-m-b-r-down div {
		text-align: left;
	}

	.m-m-t-b-title {
		font-size: 20px;
		font-weight: 600;
		height: 30px;
		line-height: 30px;
	}

	.m-m-t-b-foot div {
		margin-right: 4px;
		padding: 8px 8px;
		box-sizing: border-box;
		border-radius: 2px;
		font-size: 12px;
		color: white;
	}

	.m-m-t-b-foot {
		display: none;
		margin-top: 16px
	}

	.m-m-t-b-body {
		margin-top: 8px;
		font-size: 14px;
		color: #676b73;



	}

	.m-m-t-b-foot {
		display: flex;

	}

	.m-m-t-b-right {
		width: 540px;
		text-align: center;
	}

	.v-h-f-mid li:hover {
		color: #538cff;
	}

	.m-m-t-body {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-bottom: 60px;

	}

	.m-m-t-b-left {
		width: 620px;
		overflow: hidden;
		height: 504px;

	}

	.m-m-t-b-left img {
		width: 620px;
		height: 504px;
		display: block;
	}

	.m-m-t-h2 {
		margin-bottom: 60px;
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center;
	}

	.m-m-t-h2 span {
		color: #1861f2;
	}

	.m-homePage-touch {
		margin-top: 120px;
		text-align: center;
		margin-bottom: 60px;
	}

	.m-h-d-body {
		height: 105px;
		line-height: 150%;
		text-align: left;
		font-size: 14px;
		font-weight: 400;
		color: #676b73;
		width: 242px;
		margin: 0 auto;
		margin-top: 16px;
	}

	.m-h-d-foot div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 12px 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 140px;


	}

	.m-h-d-foots div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}

	.m-h-d-foot div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}

	.m-h-d-foots {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}

	.m-h-d-foot {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}

	.m-h-d-foots div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 115px;
	}

	.m-h-down {
		height: 240px;
		width: 282px;
		margin-top: -12px;
		padding-top: 24px;
		background-color: white;
		z-index: -1;
		border-radius: 12px;
	}




	.m-h-d-head {
		display: flex;
		width: 242px;
		margin: 0 auto;
		height: 34px;

	}

	.m-h-d-head div {
		height: 34px;
		line-height: 34px;
		font-size: 20px;
		font-weight: 600;
	}

	.m-h-down img {
		width: 34px;
		height: 34px;
		margin-right: 12px;
	}

	.m-h-img img {
		width: 100%;
		z-index: -1;
	}

	.m-h-body {
		display: flex;
		justify-content: space-between;
		height: 420px;
	}

	.m-h-body>div {
		height: 360px;
		width: 282px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 8px 15px -8px rgba(25, 34, 51, 0.1);
		border-radius: 12px;
	}

	@keyframes myfirst {
		from {
			height: 360px;
			width: 282px;
		}

		to {
			height: 370px;
			width: 300px;
		}
	}

	.m-h-body>div:hover {
		height: 370px;
		width: 300px;
		animation: myfirst 0.3s;
		-webkit-animation: myfirst 0.3s;
	}

	.m-h-img {
		z-index: -1;
	}

	.m-free {

		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;

		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
		cursor: pointer;
	}

	.m-free:hover {
		background-color: #7ba7ff;
	}

	.m-h-mid {
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center
	}

	body {
		margin: 0;
		padding: 0;
	}

	.all {
		min-width: 1200px;
		width: 100%;
	}



	.v-head {
		width: 100%;
		display: block;
		background-color: transparent;

		top: 0;
		width: 100%;


		min-width: 1200px;
		z-index: 10;
	}

	.v-head:hover {
		border: none;
	}

	.head:hover {
		background-color: white;
		animation: 2s;
	}

	.swiper-pagination-bullet {
		width: 40px !important;
		height: 4px !important;
		border-radius: 10px !important;
		background-color: #8e9091 !important;

	}

	.swiper-pagination {
		text-align: left !important;
		margin-left: 10%;
		top: 460px;
		width: 70%;
	}



	.v-h-all {
		width: 94%;
		height: 28px;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;

	}

	.v-h-left {
		display: flex;
		width: 40%;

	}

	.h-l-a {
		font-size: 12px;
		text-align: center;
		text-decoration: none;
		color: rgba(26, 34, 51, 0.5);
		height: 16px;
		margin-top: 6px;
		display: block;
		box-sizing: border-box;
	}

	.v-h-left div {
		width: 25%;
		text-align: center;
		display: block;
		box-sizing: border-box;
	}

	.v-h-foot {
		width: 94%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.v-h-f-left {

		height: 30px;
		margin-top: 15px;

	}

	.v-h-f-left img {

		height: 30px;

	}



	.a-b-all {
		margin: 0 auto;
		color: #fff;
		font-size: 24px;
		display: flex;
		justify-content: space-between;
		width: 94%;
		margin: 0 auto;
	}

	.a-b-left {
		display: flex;
		align-items: flex-start
	}

	.b-try {
		display: block;
		background: #5090ff;
		border-radius: 5px;
		width: 144px;
		border: 0;
		font-size: 16px;
		color: #fff;
		text-align: center;
		text-decoration: none;
	}

	.b-x {
		color: #fff;
		vertical-align: top;
		margin-left: 15px;
		position: relative;
		top: -15px;
		text-decoration: none;
		cursor: pointer;
	}

	.v-h-foot {
		height: 60px;
		top: 0;
		width: 94%;
		background-color: transparent;
		border-bottom: 1px solid transparent;
		z-index: 99;
		display: flex;

	}

	.v-h-f-mid {
		min-width: 300px;
		display: flex;
		text-decoration: none;
		list-style: none;
	}

	.v-h-f-mid li {
		height: 60px;
		text-align: center;
		line-height: 60px;
		cursor: pointer;
		padding: 0 20px;
	}

	.v-h-f-mid li span {
		font-size: 16px;
	}

	.v-h-f-mid li i {
		font-size: 16px;
	}

	.v-h-f-right {
		height: 60px;
	}

	.free-try {
		padding: 6px 16px;
		line-height: 1.2;
		font-size: 14px;
		letter-spacing: 0;
		border-radius: 16px;
		margin-top: 15px;
		background-color: #528cff;
		color: white;
		cursor: pointer;
	}

	.swipers {
		z-index: -1;
		width: 100%;
		overflow: hidden;
		min-width: 1200px;
	}

	.swper {
		width: 100%;
		min-width: 1200px;
		height: 580px;
		overflow: hidden;
		z-index: 1;
	}

	.swiper-slide {
		z-index: 1;
	}

	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-pagination {
		width: 80% !important;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		height: 580px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.customer {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
	}

	.c-img {
		width: 50%;
	}

	.cust-img {
		width: 100%;
		height: auto !important;
		margin-top: 100px;
	}

	.cust-swpier {
		width: 210px;
		height: 32px;
		overflow: hidden;
	}

	.cust-body {
		font-size: 24px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
	}

	.cust-title {
		font-weight: 600;
		font-size: 48px;
		text-align: left;
	}

	.customer-left {
		width: 50%;
		height: 260px;
		margin-top: 150px;
	}

	.cust-bottom {
		display: flex;
		font-size: 20px;
		margin-top: 35px;
	}

	.c-auto {
		display: flex;
		line-height: 54px;
		margin-left: 30px;
		cursor: pointer;
	}

	.c-auto:hover {
		color: #83acff;
	}

	.c-auto .iconfont {
		font-size: 28px;
	}

	.c-free {
		display: inline-block;
		position: relative;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #3578ff;
		overflow: hidden;
		padding: 12px 52px 12px 52px;
		font-size: 20px;
		border-radius: 27px;
		cursor: pointer;
	}

	.c-free:hover {
		background-color: #568dfc;
	}

	.operation {
		width: 100%;
	}

	.o-body {

		overflow: hidden;
		display: flex;
		position: relative;
		margin: -40px auto 0;
		justify-content: center;
		z-index: 0;
		height: 172px;
		padding: 16px 0;
		box-sizing: border-box;
		box-shadow: 0px 0px 1px rgb(0 0 0 / 40%), 0px 0px 40px -10px rgb(18 20 26 / 10%);
		min-width: 1200px;
		background-color: #fff;
	}

	.o-b-b {
		width: 25%;
		padding: 0 24px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer
	}

	.o-b-b img {
		width: 78px;
		height: 104px;
		border-radius: 4px;
	}

	.o-title {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		line-height: 24px;
		font-weight: 600;
		font-size: 15px
	}

	.o-down {
		display: flex;
	}

	.o-d-left {
		line-height: 19.5px;
		font-size: 13px;
		color: #676b73
	}

	.o-d-all {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 166px;
		height: 104px;
	}

	.o-d-right {
		display: flex;
		align-items: center;
		padding: 4px 12px;
		margin-right: auto;
		overflow: hidden;
		line-height: 150%;
		color: #630;
		font-size: 13px;
		background-color: #ffe6cc;
		border-radius: 14px;
	}

	.o-d-right:hover {
		background-color: #ffce96;
	}

	.m-homePage-trust-cnt {
		margin: 0 auto;
		width: 1200px;
		text-align: center;
		margin-top: 80px;

	}

	.toger {
		margin: 20px 0 40px;
		width: 1200px
	}

	.m-homePage-scene-cnt {
		margin: 0 auto;
		width: 1200px;
		text-align: center;
		margin-top: 80px;
		height: 620px;

	}

	.m-homePage-scene-cnt h2 {
		margin-bottom: 60px;
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center
	}
}
</style>